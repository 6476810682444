/*---------------------
-----------------------
        Général
-----------------------
---------------------*/

:root{
  --rouge:#C26573;
  --vert:#C2C880;
  --gris0:#EAEAEA;
  --gris1:#D1CFCF;
  --gris2:#444343;
}

*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
@font-face {
  font-family: "SailecLight";
  src: url("fonts/Sailec\ Light.ttf");
}
@font-face {
  font-family: "SailecMedium";
  src: url("fonts/Sailec\ Medium.ttf");
}
@font-face {
  font-family: 'Multicolor';
  src: url('fonts/Multicolore.otf');
}
body{
  background-color:var(--gris0);
}
#root{
  padding-bottom: 65px;
}
h2{
  font-family: 'Multicolor';
}

/*---------------------
-----------------------
        Home
-----------------------
---------------------*/

/* Navbar */
.nav {
  background-color: #D1CFCF;
  height:8vh;
  width:100vw;
  position:fixed;
  bottom:0;
  left:0;
  display:flex;
  align-items: center;
  align-content: center;
}

.ajouter {
  background-image:url('images/icon_ajouter.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width:25vw;
  height:6vh;
}

.logo_home {
  background-image:url('images/logo_reduit.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width:50vw;
  height:8vh;
  border-radius:100%;
}

.login {
  background-image:url('images/connexion.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width:25vw;
  height:5vh;
}

/* Logo + carousel*/
.logo{
  width: 80vw;
  padding:10px;
}

div#slider {
  width: 100%;
  max-width: 1000px;
}

div#slider figure {
  position: relative;
  width: 500%;
  margin: 0;
  padding: 0;
  font-size: 0;
  text-align: left;
}

div#slider figure img {
  width: 20%;
  height: auto;
  float: left;
}

div#slider {
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  margin-bottom:10px;
}

@keyframes slidy {
  0% { left: 0%; }
  20% { left: 0%; }
  25% { left: -100%; }
  45% { left: -100%; }
  50% { left: -200%; }
  70% { left: -200%; }
  75% { left: -300%; }
  95% { left: -300%; }
  100% { left: -400%; }
}
div#slider figure {
  position: relative;
  width: 500%;
  margin: 0;
  padding: 0;
  font-size: 0;
  left: 0;
  text-align: left;
  animation: 30s slidy infinite;
}

/* Search bar */
.about-bck {
  background:#EAEAEA;
  width:100vw;
  height:100vh;
}
#search {
  background-color: white;
  border: none;
  border-radius: 1vh;
  width:70vw;
  height:4vh;
  color: #444343;
  font-family: SailecLight;
  outline: none;

}
.formsearch{
  display:flex;
  justify-content: center;
  margin: 20px 0px 20px 0px;
  outline: none;
}

#loupe {
  background-image:url(images/loupe.svg);
  width:7vw;
  height:4vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position:relative;
  background-color:transparent;
  top: 0;
  left:0;
  border:none;
  outline: none;
}

/* Quizz list */
.quizz_card{
  background-color:var(--gris1);
  width:95%;
  margin:auto;
  display:flex;
  margin-bottom:15px;
  border-radius: 10px;
}
.image{
  width:30%;
  padding:10px;
}
.image > img{
  width:100%;
  height:100%;
  border-radius:5px;
}
.infos{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding:5px 5px 5px 0px;
  width:70%;
}
.infos > .title{
  text-transform: uppercase;
  font-family: 'Multicolor';
  margin:0;
}
.infos > p{
  margin:0;
  font-size:0.9rem;
  font-family: 'SailecLight';
}
.infos > .play_link{
  background-color: var(--rouge);
  color:white;
  text-align: center;
  text-decoration: none;
  width:50%;
  padding:2px;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: 'SailecLight';
}

/*---------------------
-----------------------
        Quizz page
-----------------------
---------------------*/
.quizzcontent{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-family: 'SailecLight';
}
.imganswer{
  width:36vw;
  height:36vw;
}
.nbQuestion{
  color:var(--rouge);
  text-transform: uppercase;
}
.infosQuestion{
  margin-top: 30px;
  font-size: 18px;
}
.scorePartie{
  text-align: center;
  font-size: 18px;
}
.quizzName{
  text-align: center;
  text-transform: uppercase;
  padding-top:5px;
  padding-bottom:15px;
  font-family: 'Multicolor';
}
.quizzcontent > h3{
  margin-top: 10px;
  font-size: 20px;
  text-align: center;
}
.quizzcontent > ul{
  display:flex;
  flex-wrap:wrap;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
  padding-top:15px;
  padding-bottom:25px;
}
.quizzcontent > ul > li{
  width:90%;
  padding-top:10px;
  text-align: center;
}
.buttondiv{
  display:flex;
  justify-content: center;
}
.validate_button{
  background-color: var(--rouge);
  color:white;
  width:50%;
  text-align: center;
  text-decoration: none;
  padding:10px;
  border-radius: 20px;
  text-transform: uppercase;
}
.checked{
  background-color: var(--vert);
  color: white;
}
.buttonanswer{
  border:1px solid black;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
}
.notcheckedimg{
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
}
.checkedimg{
  filter: none;
  -webkit-filter: none
}
.imgfinquizz{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width:50%;
  margin-bottom: 30px;
  border-radius:10px
}
.li_img{
  width:50%!important;
}
.sentencefinish{
  width: 80%;
  margin-top: 10px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-family: 'SailecLight';
}
.sentencefinish link{
  text-decoration: none;
}
.lienFinish{
  text-decoration: none;
  text-transform: uppercase;
  color: var(--rouge);
  font-weight: bold;
}
.scoreFinal{
  color: var(--rouge);
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}
.sentencefinish > a{
  text-decoration: none;
}
/*---------------------
-----------------------
        Add quizz
-----------------------
---------------------*/
.addRep{
  display: flex;
  justify-content:space-around;
  align-items:center;
  margin-top: 10px;
}
.Rep form{
  width: 100%;
}
.coche{
  display: flex;
  background-color: red;
}
.addRep div div {
  display: flex;
  align-items: center;
}
.addRep div div [type="text"]{
  width:150px;
}
.score{
  width: 20%;
}
.quest{
  width:90%;
}
.endRep > h2{
  margin-top:80px;
  text-align: center;
  padding-bottom:50px;
}













































/*---------------------
-----------------------
   Login / Connexion
-----------------------
---------------------*/
.log{
  text-align: center;
}

.log h2{
  color: #444343;
  margin-top: 80px;
  margin-bottom: 30px;
  font-size: 28px;
  font-family: 'Multicolor';
}

.log form{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.log p{
  margin-top: 40px;
  font-size: 18px;
  font-family: 'SailecLight';
}

.infosLog{
  display: flex;
  flex-direction: column;
}

.infosLog input{
  border: none;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 24px;
  font-size: 18px;
}

.buttonLog{
  border: none;
  border-radius: 15px;
  padding: 6px;
  text-transform: uppercase;
  color: white;
  background-color: #C26573;
  width: 60%;
  font-size: 16px;
  margin-top: 15px;
}

.lienLog{
  color: #444343;
  font-weight: bold;
  text-decoration: none;
}

#sentencepwd{
  display: none;
}

/*---------------------
-----------------------
   Barre nav connecté
-----------------------
---------------------*/

.ajouter2 {
  background-image:url('images/icon_ajouter.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width:20vw;
  height:6vh;
}
.trophee {
  background-image:url('images/trophee.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width:20vw;
  height:6vh;
}
.logo_home2 {
  background-image:url('images/logo_reduit.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width:20vw;
  height:8vh;
  border-radius:100%;
}
.profil {
  background-image:url('images/login.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width:20vw;
  height:6vh;
}
.deconnexion {
  background-image:url('images/deconnexion.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width:20vw;
  height:6vh;
}



/*---------------------
-----------------------
   Add quiz
-----------------------
---------------------*/
.connexioncouleur{
  margin-top: 20px;
  background-image:url('images/connexion_color.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 40vh;
}




/*---------------------
-----------------------
   Profil
-----------------------
---------------------*/
.pwd{
  display: none;
}



/*---------------------
-----------------------
   Leaderboard
-----------------------
---------------------*/
.lead{
  text-align: center;
}
.lead h2{
  color: #444343;
  margin-bottom: 30px;
}
.lead div {
  display: flex;
  justify-content: space-evenly;
}
.lead .divlead{
  font-family: 'SailecLight';
  border: 2px solid #d1cfcf;
  margin: auto;
  width: 80vw;
  border-radius: 10px;
  height: 100%;
  overflow:hidden;
  justify-content: center;
  align-items: center;
  color: #444343;
  margin-bottom: 10px;
  font-size: 18px;
}
.lead .me{
  border: 4px solid var(--rouge);
  font-family: 'SailecMedium';
}
.lead .divlead >div:nth-child(1){
  width:20%;
}
.lead .divlead >div:nth-child(2){
  width:60%;
}
.lead .divlead >div:nth-child(3){
  width:26%;
}
.lead .color0 {
  background: gold;
  padding: 5px;
  color: white;
  font-weight: bold;
  font-family: 'SailecMedium';
}
.lead .color1{
  background: silver;
  padding: 5px;
  color: white;
  font-weight: bold;
  font-family: 'SailecMedium';
}
.lead .color2 {
  background: #614e1a;
  padding: 5px;
  color: white;
  font-weight: bold;
  font-family: 'SailecMedium';
}
.lead .color {
  background: transparent;
  padding: 5px;
  font-family: 'SailecMedium';
}
.myScore{
  margin-top: 15px;
  font-size: 20px;
  font-family: 'SailecMedium';
}


/*---------------------
-----------------------
   Fin quiz
-----------------------
---------------------*/
.textFinal{
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.linkHome{
  text-decoration: none;
  text-transform: uppercase;
  color: var(--rouge);
  font-weight: bold;
  display: inline-block;
}
.nameQuiz{
  font-family: 'Multicolor';
}
